<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="名称：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入名称" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    功能区域-->
    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd" >新增</el-button>
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
<!--      <el-button type="primary" @click="handleBatchAddSort" >批量设置排序</el-button>-->
<!--      <el-button type="primary" @click="handleBatchAddColor" >批量设置背景色</el-button>-->
    </div>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="categoryTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="分类名称"  align="center">
          <template #default="scope">{{scope.row.name}}</template>
        </el-table-column>
        <el-table-column label="级别"  align="center">
          <template #default="scope">{{scope.row.levelName}}</template>
        </el-table-column>
        <el-table-column label="排序"  align="center">
          <template #default="scope">{{scope.row.sort}}</template>
        </el-table-column>
        <el-table-column label="设置"  align="center">
          <template #default="scope">
            <el-button size="mini" @click="handleShowNextLevel(scope.row)">查看下级</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template #default="scope">
<!--            <el-button size="mini" @click="handleUpdateColor(scope.row)">设置背景色</el-button>-->
            <el-button size="mini" @click="handleUpdateSort(scope.row)">设置排序</el-button>
            <el-button size="mini" @click="handleUpdate(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEditFlag == true?'编辑':'添加'"
        v-model="dialogVisible"
        width="40%">
      <el-form :model="categoryModel"
               :rules="rules"
               ref="categoryFrom"
               label-width="150px"
               size="small">
        <el-form-item label="名称：" prop="name">
          <el-input v-model="categoryModel.name" style="width: 80%" clearable></el-input>
          <el-button type="primary" style="margin-left: 20px;" v-if="!isEditFlag" @click="handleAddSecond()">添 加</el-button>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input-number v-model="categoryModel.sort" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="已选分类："  v-if="!isEditFlag" >
          <el-select v-model="selectChildrenArr"
                     multiple
                     placeholder="请选择分类" style="width: 80%">
            <el-option
                v-for="item in selectProductCateList"
                :key="item.name"
                :label="item.name"
                :value="item.name">

            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级分类：">
          <el-cascader
              style="width: 80%"
              clearable  filterable
              :props="{ label: 'name', value: 'id', checkStrictly: true }"
              v-model="categoryModel.parent"
              :options="productCateOptions">
          </el-cascader>
        </el-form-item>
        <el-form-item label="背景色：">
          <el-color-picker v-model="categoryModel.back_color" style="width: 300px;"></el-color-picker>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleDialogConfirm('categoryFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>

    <el-dialog
        title="设置排序"
        v-model="dialogVisibleSort"
        width="40%">
      <el-form :model="categoryModel"
               ref="categoryFrom"
               label-width="150px"
               size="small">
        <el-form-item label="排序：">
          <el-input-number v-model="categoryModel.sort" :min="1"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleSort = false">取 消</el-button>
            <el-button type="primary" @click="handleBatchAddSortConfirm('categoryFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>

    <el-dialog
        title="设置背景色"
        v-model="dialogVisibleColor"
        width="40%">
      <el-form :model="categoryModel"
               ref="categoryFrom"
               label-width="150px"
               size="small">
        <el-form-item label="背景色：">
          <el-color-picker v-model="categoryModel.back_color" style="width: 300px;"></el-color-picker>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleColor = false">取 消</el-button>
            <el-button type="primary" @click="handleBatchAddColorConfirm('categoryFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import {
  batchDeleteCategory,
  deleteCategory,
  fetchList,
  createCategory,
  updateCategory,
  fetchAllParentCate, fetchAllCateWithChildren, createCategoryForm, batchAddCateSort, batchAddCateColor
} from "@/api/category";
import {batchAddFilePrice} from "@/api/file";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
  parent: 0,
};

const defaultCategory = {
  name: null,
  parent: 0,
  sort: 1,
  back_color: null,
};

export default {
  name: 'Category',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      rules: {
        name: [{required: true, message: '请输入', trigger: 'blur'},],
      },
      parent: 0,
      dialogVisible: false,
      isEditFlag: false,
      categoryModel: Object.assign({}, defaultCategory),
      selectProductCateList: [],


      selectProductCateValue: [],
      productCateOptions: [],

      selectChildrenArr: [],

      dialogVisibleSort: false,
      dialogVisibleColor: false,
    }
  },
  created() {
    this.resetParent();
    this.getTableList();
  },
  watch: {
    $route() {
      this.resetParent();
      this.getTableList();
    }
  },
  methods: {
    //重置Parent
    resetParent(){
      this.listQuery.pageNum = 1;
      if (this.$route.query.parent != null) {
        this.listQuery.parent = this.$route.query.parent;
        this.categoryModel.parent = this.$route.query.parent;
      } else {
        this.listQuery.parent = 0;
      }
    },
    //查看下级
    handleShowNextLevel(row) {
      this.$router.push({path: '/cate', query: {parent: row.id}})
    },


    //批量设置排序路由
    handleBatchAddSort() {
      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }
      this.dialogVisibleSort = true;
    },
    //修改排序
    handleUpdateSort(row) {
      this.ids = [];
      this.dialogVisibleSort = true;
      this.categoryModel = Object.assign({},row);
      this.categoryModel.sort = parseInt(row.sort);
    },

    //批量设置排序
    handleBatchAddSortConfirm() {
      if(this.categoryModel.id){
        this.ids.push(this.categoryModel.id);
      }

      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }

      this.$confirm('确定设置排序吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
          "sort": this.categoryModel.sort,
        };

        batchAddCateSort(data).then(res => {
          if (res.code === '0') {
            this.$message({
              message: '设置成功',
              type: 'success',
            });
          }else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.dialogVisibleSort =false;
          this.getTableList();
        });

      });
    },

    //批量设置背景色路由
    handleBatchAddColor() {
      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }
      this.categoryModel.back_color = null;
      this.dialogVisibleColor = true;
    },
    //修改背景色
    handleUpdateColor(row) {
      this.ids = [];
      this.dialogVisibleColor = true;
      this.categoryModel = Object.assign({},row);
    },
    //批量设置背景色
    handleBatchAddColorConfirm() {
      if(this.categoryModel.id){
        this.ids.push(this.categoryModel.id);
      }

      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }

      this.$confirm('确定设置背景色吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
          "backColor": this.categoryModel.back_color,
        };

        batchAddCateColor(data).then(res => {
          if (res.code === '0') {
            this.$message({
              message: '设置成功',
              type: 'success',
            });
          }else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.dialogVisibleColor =false;
          this.getTableList();
        });

      });
    },



    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },
    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": row.id,
        };
        deleteCategory(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
        };
        batchDeleteCategory(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "批量删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //列表
    getTableList() {
      this.listLoading = true;
      fetchList(this.listQuery).then(res => {
        if (res.code === '0') {
          this.listLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      })

      this.getSelectProductCateList();

    },
    //添加路由
    handleAdd() {
      this.dialogVisible = true;
      this.isEditFlag = false;
      this.selectProductCateValue = [];
      this.categoryModel = Object.assign({},defaultCategory);

      if (this.$route.query.parent != null) {
        this.categoryModel.parent = Number(this.$route.query.parent);

        this.selectProductCateValue.push(Number(this.$route.query.parent));
        this.categoryModel.parent = this.selectProductCateValue[this.selectProductCateValue.length - 1];
      }else{
        this.selectProductCateValue = [];
      }

      this.selectChildrenArr = [];
      this.selectProductCateList = [];

    },
    //修改路由
    handleUpdate(row) {
      this.dialogVisible = true;
      this.isEditFlag = true;
      this.categoryModel = Object.assign({},row);

      this.selectChildrenArr = [];
      this.selectProductCateList = [];
    },
    //弹窗的分类列表
    getSelectProductCateList() {

      fetchAllCateWithChildren().then(res => {
        let responseData = res.data;
        this.productCateOptions = [];
        for (let i in responseData ){
          this.productCateOptions.push(responseData[i])
        }
        this.productCateOptions.unshift({id: 0, name: '无上级分类'});
      })

    },

    handleAddSecond() {
      if(!this.categoryModel.name){
        this.$message.warning('名称不能为空');
        return;
      }
      if(this.selectChildrenArr.includes(this.categoryModel.name)){
        this.$message.warning('已存在当前分类，禁止重复');
        return;
      }

      this.selectProductCateList.push({name: this.categoryModel.name});

      this.selectChildrenArr.push(this.categoryModel.name);

    },
    //确认操作
    handleDialogConfirm(formName) {

      if(Array.isArray(this.categoryModel.parent)){
        this.categoryModel.parent = this.categoryModel.parent[this.categoryModel.parent.length - 1];
      }
      let addResultArray = this.selectChildrenArr;
      console.log(this.categoryModel.parent);
      console.log(this.categoryModel);
      console.log(addResultArray);

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.isEditFlag) {
              updateCategory(this.categoryModel).then(res => {
                if (res.code === '0') {
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                  });
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });

            }else {

              let addResultArray = this.selectChildrenArr;

              const form = new FormData();
              form.append('add', addResultArray);

              form.append('parent',this.categoryModel.parent);
              form.append('sort',this.categoryModel.sort);

              createCategoryForm(form).then(res => {
                if (res.code === '0') {
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });
            }
          });
        }
      });
    },

  }
}
</script>
